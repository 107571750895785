import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commitment-content" },
    [
      _c("div", { staticClass: "commitment-header" }, [
        _c("h1", { staticClass: "commitment-header-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("commitment_document_title")) + " "),
        ]),
        _c("p", { staticClass: "commitment-header-subtitle" }, [
          _vm._v(" " + _vm._s(_vm.$t("commitment_document_sub_title")) + " "),
        ]),
      ]),
      _c(VForm, { staticClass: "commitment-form-container" }, [
        _c("div", { staticClass: "commitment-form-terms-br" }, [
          _c("h2", { staticClass: "commitment-form-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("term_title_language_pt")) + " "),
          ]),
          _c(
            "div",
            [
              _c(
                "span",
                {
                  staticClass:
                    "commitment-form-label commitment-form-label-title",
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("commitment_term_key", { language: "pt" }))
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "commitment-form-label commitment-form-label-sub-title",
                },
                [_vm._v(_vm._s(_vm.$t("insert_document_link")))]
              ),
              _c(VTextField, {
                staticClass: "commitment-form-input-text",
                attrs: {
                  "data-test": "Round:Commitment:CommitmentTerm",
                  color: "primary",
                  dense: "",
                  outlined: "",
                },
                on: {
                  change: function (e) {
                    return _vm.completeCommitmentTermUrl(e, "pt")
                  },
                },
                model: {
                  value: _vm.commitmentTermUrlPt,
                  callback: function ($$v) {
                    _vm.commitmentTermUrlPt = $$v
                  },
                  expression: "commitmentTermUrlPt",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "span",
                {
                  staticClass:
                    "commitment-form-label commitment-form-label-title",
                },
                [_vm._v(_vm._s(_vm.$t("proxy_key", { language: "pt" })))]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "commitment-form-label commitment-form-label-sub-title",
                },
                [_vm._v(_vm._s(_vm.$t("insert_document_link")))]
              ),
              _c(VTextField, {
                staticClass: "commitment-form-input-text",
                attrs: {
                  "data-test": "Round:Commitment:ProxyUrl",
                  color: "primary",
                  dense: "",
                  outlined: "",
                },
                on: {
                  change: function (e) {
                    return _vm.completeInvestmentProxyUrl(e, "pt")
                  },
                },
                model: {
                  value: _vm.investmentProxyUrlPt,
                  callback: function ($$v) {
                    _vm.investmentProxyUrlPt = $$v
                  },
                  expression: "investmentProxyUrlPt",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "commitment-form-en" }, [
          _c("h2", { staticClass: "commitment-form-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("term_title_language_en")) + " "),
          ]),
          _c(
            "div",
            [
              _c(
                "span",
                {
                  staticClass:
                    "commitment-form-label commitment-form-label-title",
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("commitment_term_key", { language: "en" }))
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "commitment-form-label commitment-form-label-sub-title",
                },
                [_vm._v(_vm._s(_vm.$t("insert_document_link")))]
              ),
              _c(VTextField, {
                staticClass: "commitment-form-input-text",
                attrs: {
                  "data-test": "Round:Commitment:CommitmentTermEnglish",
                  color: "primary",
                  dense: "",
                  outlined: "",
                },
                on: {
                  change: function (e) {
                    return _vm.completeCommitmentTermUrl(e, "en")
                  },
                },
                model: {
                  value: _vm.commitmentTermUrlEn,
                  callback: function ($$v) {
                    _vm.commitmentTermUrlEn = $$v
                  },
                  expression: "commitmentTermUrlEn",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "span",
                {
                  staticClass:
                    "commitment-form-label commitment-form-label-title",
                },
                [_vm._v(_vm._s(_vm.$t("proxy_key", { language: "en" })))]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "commitment-form-label commitment-form-label-sub-title",
                },
                [_vm._v(_vm._s(_vm.$t("insert_document_link")))]
              ),
              _c(VTextField, {
                staticClass: "commitment-form-input-text",
                attrs: {
                  "data-test": "Round:Commitment:ProxyUrlEnglish",
                  color: "primary",
                  dense: "",
                  outlined: "",
                },
                on: {
                  change: function (e) {
                    return _vm.completeInvestmentProxyUrl(e, "en")
                  },
                },
                model: {
                  value: _vm.investmentProxyUrlEn,
                  callback: function ($$v) {
                    _vm.investmentProxyUrlEn = $$v
                  },
                  expression: "investmentProxyUrlEn",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.partnerConditionInvestmentRounds.length > 0
        ? _c(
            "ul",
            _vm._l(_vm.partnerConditionInvestmentRounds, function (item, i) {
              return _c("li", { key: i }, [
                _c(
                  "h3",
                  { staticClass: "commitment-partner-condition-title" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$tc("specials_condition_partner", 1) +
                            " " +
                            _vm.getNamePartnerSelected(item.partnerB2bId)
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("h3", { staticClass: "commitment-form-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("term_title_language_pt")) + " "),
                ]),
                _c("p", { staticClass: "commitment-partner-condition-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("commitment_term_key", { language: "pt" }))
                  ),
                  _c(
                    "span",
                    { staticClass: "commitment-partner-condition-text-url" },
                    [
                      _vm._v(
                        _vm._s(JSON.parse(item.partnerCommitmentTermUrl).pt) +
                          " "
                      ),
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "fa-solid fa-pencil",
                          "data-test":
                            "Round:Commitment:ButtonToEditPartnerCommitmentTermUrl",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openEditDialog(item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("p", { staticClass: "commitment-partner-condition-text" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("proxy_key", { language: "pt" })) + " "
                  ),
                  _c(
                    "span",
                    { staticClass: "commitment-partner-condition-text-url" },
                    [
                      _vm._v(
                        _vm._s(JSON.parse(item.partnerInvestmentProxyUrl).pt) +
                          " "
                      ),
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "fa-solid fa-pencil",
                          "data-test":
                            "Round:Commitment:ButtonToEditPartnerInvestmentProxyUrl",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openEditDialog(item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("h3", { staticClass: "commitment-form-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("term_title_language_en")) + " "),
                ]),
                _c("p", { staticClass: "commitment-partner-condition-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("commitment_term_key", { language: "en" }))
                  ),
                  _c(
                    "span",
                    { staticClass: "commitment-partner-condition-text-url" },
                    [
                      _vm._v(
                        _vm._s(JSON.parse(item.partnerCommitmentTermUrl).en) +
                          " "
                      ),
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "fa-solid fa-pencil",
                          "data-test":
                            "Round:Commitment:ButtonToEditPartnerCommitmentTermUrl",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openEditDialog(item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("p", { staticClass: "commitment-partner-condition-text" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("proxy_key", { language: "en" })) + " "
                  ),
                  _c(
                    "span",
                    { staticClass: "commitment-partner-condition-text-url" },
                    [
                      _vm._v(
                        _vm._s(JSON.parse(item.partnerInvestmentProxyUrl).en) +
                          " "
                      ),
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "fa-solid fa-pencil",
                          "data-test":
                            "Round:Commitment:ButtonToEditPartnerInvestmentProxyUrl",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openEditDialog(item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "commitment-form-button-condition-partner" },
        [
          _c("NewDxaButton", {
            attrs: {
              "data-test": "Round:Commitment:BtnAddSpecialPartnerCondition",
              outline: true,
              title: _vm.$t("add_specials_condition_partner"),
            },
            on: { btnFunction: _vm.openDialog },
          }),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("CommitmentPartnerModal", {
                attrs: {
                  editDialog: _vm.editDialog,
                  dataToEdit: _vm.dataToEdit,
                },
                on: {
                  conditionPartner: _vm.conditionPartner,
                  closeDialog: _vm.closeDialog,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }