import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "briefing-and-permission-content" }, [
    _c("div", { staticClass: "briefing-content" }, [
      _c("h1", { staticClass: "briefing-content-title" }, [
        _vm._v(_vm._s(_vm.$t("round_briefing_title"))),
      ]),
      _c("div", { staticClass: "briefing-disclaimer-content" }, [
        _c("span", { staticClass: "briefing-content-sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("round_briefing_sub_title")) + " "),
        ]),
        _c("span", { staticClass: "briefing-and-permissions-disclaimer" }, [
          _vm._v(_vm._s(_vm.$t("round_briefing_disclaimer")) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "briefing-content-button" },
        [
          _c("NewDxaButton", {
            attrs: {
              disabled: !_vm.isValid,
              outline: true,
              title: _vm.$t("round_briefing_title_button"),
              "data-test": "Round:BriefingAndPermission:BtnAddInfo",
            },
            on: { btnFunction: _vm.openBriefing },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "round-briefing-permission-section" },
        [
          _c("h1", { staticClass: "briefing-content-title" }, [
            _vm._v(_vm._s(_vm.$t("permissions"))),
          ]),
          _c("div", { staticClass: "briefing-disclaimer-content" }, [
            _c("span", { staticClass: "briefing-content-sub-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("round_permission_label")) + " "),
            ]),
            _c("span", { staticClass: "briefing-and-permissions-disclaimer" }, [
              _vm._v(_vm._s(_vm.$t("round_permission_disclaimer")) + " "),
            ]),
          ]),
          _c("span", { staticClass: "round-briefing-opportunity-label" }, [
            _vm._v(" " + _vm._s(_vm.$t("round_permission_opportinity")) + " "),
          ]),
          _c(
            VRadioGroup,
            {
              staticClass: "round-briefing-radio-group",
              attrs: { row: "" },
              model: {
                value: _vm.roundData.typeOpportunity,
                callback: function ($$v) {
                  _vm.$set(_vm.roundData, "typeOpportunity", $$v)
                },
                expression: "roundData.typeOpportunity",
              },
            },
            [
              _c(VRadio, {
                attrs: {
                  value: _vm.roundTypeOpportunity.AvailableOpportunity,
                  "data-test":
                    "Round:BriefingAndPermission:RadioAvailableOpportunity",
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c(
                          "span",
                          { staticClass: "round-briefing-radio-label" },
                          [_vm._v(_vm._s(_vm.$t("opportunity_available")))]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(VRadio, {
                attrs: {
                  value: _vm.roundTypeOpportunity.ExclusiveOpportunity,
                  "data-test":
                    "Round:BriefingAndPermission:RadioExclusiveOpportunity",
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c(
                          "span",
                          { staticClass: "round-briefing-radio-label" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("opportunity_exclusive")) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "round-permission-button-section" }, [
        _c(
          "div",
          { staticClass: "round-permission-button" },
          [
            _c("NewDxaButton", {
              attrs: {
                disabled: _vm.disabledButtonUsersPermissions,
                outline: true,
                title: _vm.$t("round_user_permissions"),
                "data-test": "Round:BriefingAndPermission:BtnUserPermissions",
              },
              on: { btnFunction: _vm.openPermissions },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }