var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alert-modal-bg" }, [
    _c(
      "div",
      { staticClass: "alert-modal-content" },
      [
        _c("font-awesome-icon", {
          staticClass: "alert-modal-icon",
          attrs: { icon: "fa-solid fa-circle-xmark" },
        }),
        _c("h1", { staticClass: "alert-modal-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("round_alert_required_data_title")) + " "),
        ]),
        _c("h2", { staticClass: "alert-modal-sub-title" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("round_alert_required_data_sub_title")) + " "
          ),
        ]),
        _c("ul", { staticClass: "alert-modal-list" }, [
          _c("li", [_vm._v(_vm._s(_vm.$tc("company", 1)))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("vehicle_title")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("manager_company")))]),
        ]),
        _c(
          "div",
          { staticClass: "alert-modal-button-close" },
          [
            _c("NewDxaButton", {
              attrs: { outline: true, title: _vm.$t("close") },
              on: { btnFunction: _vm.handleClick },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "alert-modal-icon-close-box" },
      [
        _c("font-awesome-icon", {
          staticClass: "alert-modal-icon-close",
          attrs: { icon: "fa-solid fa-xmark" },
          on: { click: _vm.handleClick },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }