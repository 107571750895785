import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "round-closing-container" }, [
    _c("span", { staticClass: "round-closing-title" }, [
      _vm._v(_vm._s(_vm.$t("round_closing_documents"))),
    ]),
    _vm.isValid
      ? _c(
          "div",
          { staticClass: "round-closing-valid" },
          [
            _c("span", { staticClass: "round-closing-subtitle" }, [
              _vm._v(" " + _vm._s(_vm.$t("round_closing_title")) + " "),
            ]),
            _vm._l(
              _vm.round.investmentVehicleInvestmentRounds,
              function (vehicle, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "round-closing-documents" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loadingList[key],
                            expression: "!loadingList[key]",
                          },
                        ],
                        attrs: {
                          "data-test":
                            "Round:Closing:Vehicle:" + _vm.vehicleNames[key],
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vehicle-document-title-container" },
                          [
                            _c("span", { staticClass: "round-closing-title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("round_closing_document_title", {})
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "vehicle-name",
                                on: {
                                  click: function () {
                                    return _vm.$router.push(
                                      "/vehicle/edit/" +
                                        vehicle.investmentVehicleId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.vehicleNames[key]) + " ")]
                            ),
                            _c(
                              "span",
                              { staticClass: "vehicle-nationality" },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "icon-vehicle-nationality",
                                  attrs: { icon: "fa-solid fa-database" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getVehicleNationality(
                                        vehicle.vehicleNationality
                                      )
                                    )
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "round-closing-data-container" },
                          [
                            _c(
                              "div",
                              { staticClass: "round-closing-input-container" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "round-closing-input-title" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("round_closing_stock_type")
                                      ) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "round-closing-input-subtitle",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("round_closing_input_select")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(VAutocomplete, {
                                  staticClass: "rounds-filters-auto-complete",
                                  attrs: {
                                    "data-test":
                                      "Round:RoundClosing:SelectStockType:" +
                                      _vm.vehicleNames[key],
                                    items: _vm.stockTypes[key],
                                    "item-text": "stockTypeName",
                                    "item-value": "stockTypeId",
                                    color: "primary",
                                    dense: "",
                                    label: _vm.$t("select"),
                                    outlined: "",
                                  },
                                  on: {
                                    change: function (e) {
                                      return _vm.selectVehicleStockType(
                                        vehicle,
                                        e
                                      )
                                    },
                                  },
                                  model: {
                                    value: vehicle.stockTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(vehicle, "stockTypeId", $$v)
                                    },
                                    expression: "vehicle.stockTypeId",
                                  },
                                }),
                              ],
                              1
                            ),
                            vehicle.closingType ===
                            _vm.vehicleClosingOptionsEnum.CLOSING_SA
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "round-closing-input-container",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "round-closing-input-title",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "round_closing_adhesion_term"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "round-closing-input-subtitle",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "round_closing_clicksign_input"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(VTextField, {
                                        staticClass: "closing-form-input-text",
                                        attrs: {
                                          "data-test":
                                            "Round:RoundClosing:AdhesionTermSignatureKey",
                                          color: "primary",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value:
                                            vehicle.adhesionTermSignatureKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              vehicle,
                                              "adhesionTermSignatureKey",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vehicle.adhesionTermSignatureKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "round-closing-input-container",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "round-closing-input-title",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "newsletter_subscription"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "round-closing-input-subtitle",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "round_closing_clicksign_input"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(VTextField, {
                                        staticClass: "closing-form-input-text",
                                        attrs: {
                                          "data-test":
                                            "Round:RoundClosing:NewsletterSubscriptionSignatureKey",
                                          color: "primary",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value:
                                            vehicle.newsletterSubscriptionSignatureKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              vehicle,
                                              "newsletterSubscriptionSignatureKey",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vehicle.newsletterSubscriptionSignatureKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            vehicle.closingType ===
                            _vm.vehicleClosingOptionsEnum.CLOSING_LLC
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "round-closing-input-container",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "round-closing-input-title",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("subscription_agreement")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "round-closing-input-subtitle",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "round_closing_clicksign_input"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(VTextField, {
                                        staticClass: "closing-form-input-text",
                                        attrs: {
                                          "data-test":
                                            "Round:RoundClosing:SubscriptionAgreementSignatureKey",
                                          color: "primary",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value:
                                            vehicle.subscriptionAgreementSignatureKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              vehicle,
                                              "subscriptionAgreementSignatureKey",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "vehicle.subscriptionAgreementSignatureKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            vehicle.closingType ===
                            _vm.vehicleClosingOptionsEnum.NO_CLOSING
                              ? _c(
                                  "div",
                                  { staticClass: "no-closing-container" },
                                  [
                                    _c(
                                      "h2",
                                      { staticClass: "no-closing-title" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "no_formalization_documents"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "no-closing-sub-title" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("closing_outside_dxa"))
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loadingList[key],
                            expression: "loadingList[key]",
                          },
                        ],
                      },
                      [
                        _c(VProgressCircular, {
                          staticStyle: { "margin-top": "8px" },
                          attrs: {
                            indeterminate: "",
                            size: "24",
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }
            ),
          ],
          2
        )
      : _c("div", { staticClass: "round-closing-invalid-section" }, [
          _c("span", { staticClass: "round-closing-invalid-text1" }, [
            _vm._v(_vm._s(_vm.$t("round_closing_invalid_text1"))),
          ]),
          _c("span", { staticClass: "round-closing-invalid-text2" }, [
            _vm._v(_vm._s(_vm.$t("round_closing_invalid_text2"))),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }