var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "round-tab-container" }, [
    _c(
      "div",
      { staticClass: "round-tab-header" },
      _vm._l(_vm.tabs, function (item) {
        return _c(
          "div",
          {
            key: item.value,
            staticClass: "round-tab-item",
            attrs: { "data-test": "Round:Tabs:RoundStep:" + item.value },
            on: {
              click: function ($event) {
                return _vm.setActiveItem(item)
              },
            },
          },
          [
            _c("span", { staticClass: "round-tab-label" }, [
              _vm._v(" " + _vm._s(_vm.$t(item.label)) + " "),
            ]),
            _c("div", {
              staticClass: "round-tab-bar",
              class: { "round-tab-active": _vm.activeTab === item.value },
            }),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "round-tab-content" },
      [
        _c("RoundData", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab === _vm.roundTabsEnumData.ROUND_DATA,
              expression: "activeTab === roundTabsEnumData.ROUND_DATA",
            },
          ],
          attrs: { round: _vm.round },
        }),
        _c("Closing", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab === _vm.roundTabsEnumData.CLOSING,
              expression: "activeTab === roundTabsEnumData.CLOSING",
            },
          ],
          attrs: {
            currentTab: _vm.activeTab,
            isValid: _vm.stepIsValid,
            round: _vm.round,
          },
        }),
        _c("Commitment", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab === _vm.roundTabsEnumData.COMMITMENT,
              expression: "activeTab === roundTabsEnumData.COMMITMENT",
            },
          ],
          attrs: { round: _vm.round },
        }),
        _c("BriefingAndPermissions", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.activeTab ===
                _vm.roundTabsEnumData.BRIEFING_AND_PERMISSIONS,
              expression:
                "activeTab === roundTabsEnumData.BRIEFING_AND_PERMISSIONS",
            },
          ],
          attrs: { isValid: _vm.stepIsValid, round: _vm.round },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }