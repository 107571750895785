import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "round-data-content" }, [
    _c("div", [
      _c("span", { staticClass: "round-data-intro-text" }, [
        _vm._v(" " + _vm._s(_vm.$t("round_data_intro")) + " "),
      ]),
      _c("div", { staticClass: "round-data-form" }, [
        _c(
          "div",
          [
            _c("span", { staticClass: "round-data-form-label" }, [
              _vm._v(" " + _vm._s(_vm.$t("select_company")) + "* "),
            ]),
            _c(VAutocomplete, {
              staticClass: "rounds-filters-auto-complete",
              attrs: {
                "data-test": "Round:RoundData:SelectCompany",
                items: _vm.roundsAllCompanies,
                "item-text": "Name",
                "item-value": "CompanyId",
                color: "primary",
                dense: "",
                label: _vm.$t("select"),
                outlined: "",
                disabled: _vm.roundCompanyFieldDisabled,
                loading: _vm.roundAllCompaniesLoading,
              },
              on: { change: _vm.companyChanged },
              scopedSlots: _vm._u([
                {
                  key: "progress",
                  fn: function () {
                    return [
                      _c(VProgressCircular, {
                        staticStyle: {
                          position: "absolute",
                          right: "40px",
                          top: "10px",
                        },
                        attrs: {
                          indeterminate: "",
                          size: "20",
                          color: "primary",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.roundCompanySelected,
                callback: function ($$v) {
                  _vm.roundCompanySelected = $$v
                },
                expression: "roundCompanySelected",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("span", { staticClass: "round-data-form-label" }, [
              _vm._v(_vm._s(_vm.$t("select_investment_vehicle")) + "* "),
            ]),
            _c("span", { staticClass: "round-data-form-subtitle" }, [
              _vm._v(_vm._s(_vm.$t("mark_all_vehicles"))),
            ]),
            _c(VAutocomplete, {
              staticClass: "rounds-filters-auto-complete",
              attrs: {
                "data-test": "Round:RoundData:SelectVehicle",
                items: _vm.companyInvestmentVehicles,
                "item-text": "name",
                "item-value": "id",
                color: "primary",
                dense: "",
                label: _vm.$t("select_label"),
                outlined: "",
                multiple: "",
                "item-disabled": _vm.vehicleItemDisabled,
                loading: _vm.companyInvestmentVehiclesLoading,
              },
              on: { change: _vm.saveCompanyInvestmentVehicles },
              scopedSlots: _vm._u([
                {
                  key: "progress",
                  fn: function () {
                    return [
                      _c(VProgressCircular, {
                        staticStyle: {
                          position: "absolute",
                          right: "40px",
                          top: "10px",
                        },
                        attrs: {
                          indeterminate: "",
                          size: "20",
                          color: "primary",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.roundVehiclesSelected,
                callback: function ($$v) {
                  _vm.roundVehiclesSelected = $$v
                },
                expression: "roundVehiclesSelected",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "span",
              { staticClass: "round-data-form-label flex gap-3" },
              [
                _vm._v(" " + _vm._s(_vm.$t("select_manager_company")) + "* "),
                _c(
                  VTooltip,
                  {
                    attrs: { bottom: "", color: "gray" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g({}, on),
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "icon-tooltip-manager",
                                  attrs: { icon: "fa-solid fa-circle-info" },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("write_min_3_caracteres"))),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(VAutocomplete, {
              staticClass: "rounds-filters-auto-complete",
              attrs: {
                "data-test": "Round:RoundData:ManagerPartner",
                items: _vm.managerPartnerList,
                "item-text": "name",
                "item-value": "id",
                color: "primary",
                dense: "",
                label: _vm.$t("select_label"),
                outlined: "",
                loading: _vm.managerPartnerListLoading,
              },
              on: {
                keyup: _vm.managerPartnerChanged,
                change: _vm.saveManagerPartnerId,
                blur: _vm.handleManagerPartnerBlur,
              },
              scopedSlots: _vm._u([
                {
                  key: "progress",
                  fn: function () {
                    return [
                      _c(VProgressCircular, {
                        staticStyle: {
                          position: "absolute",
                          right: "40px",
                          top: "10px",
                        },
                        attrs: {
                          indeterminate: "",
                          size: "20",
                          color: "primary",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "no-data",
                  fn: function () {
                    return [
                      _c(
                        VListItem,
                        [
                          _c(VListItemTitle, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.managerPartnerFallback
                                    ? _vm.$t("enter_manager_company")
                                    : _vm.$t("not_found")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.selectedManagerPartner,
                callback: function ($$v) {
                  _vm.selectedManagerPartner = $$v
                },
                expression: "selectedManagerPartner",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("span", { staticClass: "round-data-form-label" }, [
              _vm._v(" " + _vm._s(_vm.$t("investment_target")) + " "),
            ]),
            _c("money", {
              staticClass: "field rounds-filters-auto-complete",
              attrs: {
                color: "primary",
                dense: "",
                outlined: "",
                prefix: "R$",
                thousands: ".",
                decimal: ",",
                min: "1",
                "data-test": "Round:RoundData:InvestmentTarget",
              },
              model: {
                value: _vm.roundInvestmentTarget,
                callback: function ($$v) {
                  _vm.roundInvestmentTarget = $$v
                },
                expression: "roundInvestmentTarget",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "round-date-row" }, [
          _c(
            "div",
            { staticClass: "round-date-container" },
            [
              _c("span", { staticClass: "round-date-title" }, [
                _vm._v(_vm._s(_vm.$t("startDate"))),
              ]),
              _c("span", { staticClass: "round-date-subtitle" }, [
                _vm._v(_vm._s(_vm.$t("can_be_changed_later")) + " "),
              ]),
              _c(
                VMenu,
                {
                  ref: "startMenuDateRef",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "auto",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            VTextField,
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: ["##/##/####"],
                                      expression: "['##/##/####']",
                                    },
                                  ],
                                  staticClass: "rounds-filters-date",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "data-test":
                                      "Round:RoundData:RoundCaptureStartDate",
                                    dense: "",
                                    outlined: "",
                                    "append-icon": "mdi-calendar",
                                    label: "DD/MM/AAAA",
                                    height: "34",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      _vm.roundStartDate =
                                        _vm.roundCaptureStartDateText
                                    },
                                  },
                                  model: {
                                    value: _vm.roundCaptureStartDateText,
                                    callback: function ($$v) {
                                      _vm.roundCaptureStartDateText = $$v
                                    },
                                    expression: "roundCaptureStartDateText",
                                  },
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.roundCaptureStartMenuDate,
                    callback: function ($$v) {
                      _vm.roundCaptureStartMenuDate = $$v
                    },
                    expression: "roundCaptureStartMenuDate",
                  },
                },
                [
                  _c(VDatePicker, {
                    attrs: {
                      locale: "pt-BR",
                      color: "primary",
                      min: "1850-01-01",
                    },
                    on: { change: _vm.saveRoundCaptureStartDate },
                    model: {
                      value: _vm.roundCaptureStartDate,
                      callback: function ($$v) {
                        _vm.roundCaptureStartDate = $$v
                      },
                      expression: "roundCaptureStartDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "round-date-container" },
            [
              _c("span", { staticClass: "round-date-title" }, [
                _vm._v(_vm._s(_vm.$t("endDate"))),
              ]),
              _c("span", { staticClass: "round-date-subtitle" }, [
                _vm._v(_vm._s(_vm.$t("can_be_changed_later")) + " "),
              ]),
              _c(
                VMenu,
                {
                  ref: "endMenuDateRef",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "auto",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            VTextField,
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: ["##/##/####"],
                                      expression: "['##/##/####']",
                                    },
                                  ],
                                  staticClass: "rounds-filters-date",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "data-test":
                                      "Round:RoundData:RoundCaptureEndDate",
                                    dense: "",
                                    outlined: "",
                                    "append-icon": "mdi-calendar",
                                    label: "DD/MM/AAAA",
                                    height: "34",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      _vm.roundEndDate =
                                        _vm.roundCaptureFinishDateText
                                    },
                                  },
                                  model: {
                                    value: _vm.roundCaptureFinishDateText,
                                    callback: function ($$v) {
                                      _vm.roundCaptureFinishDateText = $$v
                                    },
                                    expression: "roundCaptureFinishDateText",
                                  },
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.roundCaptureEndMenuDate,
                    callback: function ($$v) {
                      _vm.roundCaptureEndMenuDate = $$v
                    },
                    expression: "roundCaptureEndMenuDate",
                  },
                },
                [
                  _c(VDatePicker, {
                    attrs: {
                      locale: "pt-BR",
                      color: "primary",
                      min: "1850-01-01",
                    },
                    on: { change: _vm.saveRoundCaptureFinishDate },
                    model: {
                      value: _vm.roundCaptureFinishDate,
                      callback: function ($$v) {
                        _vm.roundCaptureFinishDate = $$v
                      },
                      expression: "roundCaptureFinishDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }