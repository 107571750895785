import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "100vh" }, attrs: { id: "rounds-bg" } },
    [
      _c("div", { attrs: { id: "rounds-content" } }, [
        _c(
          "a",
          {
            staticClass: "round-header-back-page",
            on: {
              click: function ($event) {
                return _vm.$router.push("/round/")
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "round-header-back-page-icon",
              attrs: { icon: "fa-solid fa-arrow-left" },
            }),
            _c("span", { staticClass: "round-header-back-page-text" }, [
              _vm._v(_vm._s(_vm.$t("round_details_title"))),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "rounds-header" }, [
          _c("div", [
            _c("h1", { staticClass: "rounds-title" }, [
              _vm._v(_vm._s(_vm.$t("new_round"))),
            ]),
            _c("h2", { staticClass: "rounds-sub-title" }, [
              _vm._v(_vm._s(_vm.$t("rounds_sub_title"))),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [_c("Tabs", { attrs: { round: _vm.round } })],
          1
        ),
        _c(
          "div",
          { staticClass: "round-control" },
          [
            _c(VSwitch, {
              attrs: {
                disabled: _vm.publishRoundSwitchDisabled,
                color: "primary",
                inset: "",
                "data-test": "Round:NewRound:SwitchPublishRound",
              },
              on: { change: _vm.publishRoundToPipeline },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v(" Rodada disponível no Pipeline ")]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.publishRoundPipeline,
                callback: function ($$v) {
                  _vm.publishRoundPipeline = $$v
                },
                expression: "publishRoundPipeline",
              },
            }),
            _c(
              "div",
              { staticClass: "round-save-btn-container" },
              [
                _c("NewDxaButton", {
                  staticClass: "round-save-button",
                  attrs: {
                    "data-test": "Round:NewRound:BtnSaveRound",
                    title: _vm.$t("save"),
                    loading: _vm.saveRoundLoading,
                  },
                  on: { btnFunction: _vm.submit },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        VDialog,
        {
          attrs: { "max-width": "400", width: "400" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-container" },
            [
              _vm.dialogVariant === "success"
                ? _c("font-awesome-icon", {
                    staticClass: "dialog-icon icon-success",
                    attrs: { icon: "fa-solid fa-circle-check" },
                  })
                : _vm._e(),
              _vm.dialogVariant === "error"
                ? _c("font-awesome-icon", {
                    staticClass: "dialog-icon icon-error",
                    attrs: { icon: "fa-solid fa-circle-xmark" },
                  })
                : _vm._e(),
              _c("span", { staticClass: "dialog-title" }, [
                _vm._v(_vm._s(_vm.$t(_vm.dialogData.title))),
              ]),
              _c("span", { staticClass: "dialog-subtitle" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.dialogData.subtitle, {
                      roundId: _vm.dialogData.roundId,
                      company: _vm.dialogData.company,
                    })
                  ) + " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "dialog-button-container" },
                [
                  _c("NewDxaButton", {
                    staticClass: "round-save-button",
                    attrs: {
                      outline: "",
                      title: _vm.$t("continue"),
                      "data-test": "Round:NewRound:BtnCloseDialog",
                    },
                    on: { btnFunction: _vm.resetDialog },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "564" },
          model: {
            value: _vm.requiredDataDialog,
            callback: function ($$v) {
              _vm.requiredDataDialog = $$v
            },
            expression: "requiredDataDialog",
          },
        },
        [
          _c("AlertModalRequiredData", {
            on: {
              closeDialog: function ($event) {
                _vm.requiredDataDialog = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "564" },
          model: {
            value: _vm.publishRoundPipelineAlert,
            callback: function ($$v) {
              _vm.publishRoundPipelineAlert = $$v
            },
            expression: "publishRoundPipelineAlert",
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-publish-round-pipeline-bg" },
            [
              _c(
                "div",
                { staticClass: "dialog-publish-round-pipeline-content" },
                [
                  _c("font-awesome-icon", {
                    staticClass:
                      "dialog-publish-round-pipeline-content-alert-icon",
                    attrs: { icon: "fa-solid fa-triangle-exclamation" },
                  }),
                  _c(
                    "h1",
                    {
                      staticClass:
                        "dialog-publish-round-pipeline-content-title",
                    },
                    [_vm._v(" " + _vm._s(_vm.publishRoundPipelineTitle) + " ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "dialog-publish-round-pipeline-content-text",
                    },
                    [_vm._v(" " + _vm._s(_vm.publishRoundPipelineText) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dialog-publish-round-pipeline-content-buttons",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dialog-publish-round-pipeline-content-button",
                        },
                        [
                          _c("NewDxaButton", {
                            attrs: { outline: true, title: _vm.$t("cancel") },
                            on: {
                              btnFunction: _vm.closeAlertPublishRoundPipeline,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dialog-publish-round-pipeline-content-button",
                        },
                        [
                          _c("NewDxaButton", {
                            attrs: {
                              title: _vm.publishRoundPipelineButtonText,
                              loading: _vm.publishRoundLoading,
                            },
                            on: { btnFunction: _vm.goPublishRoundPipeline },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("font-awesome-icon", {
                staticClass: "dialog-publish-round-pipeline-icon-close-dialog",
                attrs: { icon: "fa-solid fa-xmark" },
                on: { click: _vm.closeAlertPublishRoundPipeline },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "564" },
          model: {
            value: _vm.publishRoundPipelineError,
            callback: function ($$v) {
              _vm.publishRoundPipelineError = $$v
            },
            expression: "publishRoundPipelineError",
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-publish-round-pipeline-error-bg" },
            [
              _c(
                "div",
                { staticClass: "dialog-publish-round-pipeline-error-content" },
                [
                  _c("font-awesome-icon", {
                    staticClass:
                      "dialog-publish-round-pipeline-alert-icon-error",
                    attrs: { icon: "fa-solid fa-circle-xmark" },
                  }),
                  _c(
                    "h1",
                    {
                      staticClass:
                        "dialog-publish-round-pipeline-error-content-title",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("publish_round_pipeline_alert_error_title")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "dialog-publish-round-pipeline-error-content-text",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "publish_round_pipeline_alert_error_sub_title"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "dialog-publish-round-pipeline-error-content-text dialog-publish-round-pipeline-error-content-list",
                    },
                    [
                      _c("li", [_vm._v(_vm._s(_vm.$t("investment_target")))]),
                      _c("li", [
                        _vm._v(_vm._s(_vm.$t("commitment_documents"))),
                      ]),
                      _c("li", [_vm._v(_vm._s(_vm.$t("closing_documents")))]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "publish_round_pipeline_alert_error_briefing_item"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "publish_round_pipeline_alert_error_user_permissions"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-publish-round-pipeline-error-button",
                    },
                    [
                      _c("NewDxaButton", {
                        attrs: { outline: true, title: _vm.$t("close") },
                        on: { btnFunction: _vm.closePublishRoundAlertError },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("font-awesome-icon", {
                staticClass:
                  "dialog-publish-round-pipeline-error-icon-close-dialog",
                attrs: { icon: "fa-solid fa-xmark" },
                on: { click: _vm.closePublishRoundAlertError },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.publishRoundSuccess
        ? _c(
            VDialog,
            {
              attrs: { "max-width": "564" },
              model: {
                value: _vm.publishRoundSuccess,
                callback: function ($$v) {
                  _vm.publishRoundSuccess = $$v
                },
                expression: "publishRoundSuccess",
              },
            },
            [
              _c(
                "div",
                { staticClass: "dialog-publish-round-success-bg" },
                [
                  _c(
                    "div",
                    { staticClass: "dialog-publish-round-success-content" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "dialog-publish-round-success-icon",
                        attrs: { icon: "fa-solid fa-circle-check" },
                        on: {
                          click: function ($event) {
                            _vm.publishRoundPipelineError = false
                          },
                        },
                      }),
                      _c("h1", { staticClass: "dialog-publish-round-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "publish_round_pipeline_alert_success_title"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "dialog-publish-round-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "publish_round_pipeline_alert_success_text",
                                {
                                  roundId: _vm.roundData.id,
                                  companyName: _vm.roundData.companyName,
                                }
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        [
                          _c("NewDxaButton", {
                            attrs: { outline: true, title: _vm.$t("close") },
                            on: {
                              btnFunction: function ($event) {
                                _vm.publishRoundSuccess = false
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("font-awesome-icon", {
                    staticClass: "dialog-publish-round-success-icon-close",
                    attrs: { icon: "fa-solid fa-xmark" },
                    on: {
                      click: function ($event) {
                        _vm.publishRoundSuccess = false
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }